<template>
  <section class="repair-management" @click.stop="pickerclick">
    <div class="top-bar bg-white align-center">
      <el-select
          ref="typrRef"
          v-model="formSearch.type"
          placeholder="请选择员工类型"
          @change="handleSearch"
          size="small"
          style="margin-right: 10px; margin-left: 10px"
      >
        <el-option label="中介" value="中介"/>
        <el-option label="装修/软装" value="装修/软装"/>
        <el-option label="施工" value="施工"/>
        <el-option label="其他" value="其他"/>
      </el-select>
      <el-input
          v-model="formSearch.keyWord"
          style="margin: 5px; width: 250px"
          size="small"
          placeholder="请填写姓名，手机号，所属单位"
      />
      <!-- </el-select> -->
      <el-button type="primary" size="small" @click="handleSearch"
      >查询
      </el-button
      >
      <el-button size="small" @click="handleReset" style="margin: 0 7px"
      >重置
      </el-button
      >
      <el-button type="primary" size="small" @click="saveActivity"
      >新增
      </el-button
      >
      <el-button type="primary" size="small" @click="importbtn">导入</el-button>
    </div>

    <dialog-save-activity
        ref="dialogSaveActivity"
        @handleSearch="handleSearch"
    />
    <layer-assets-import ref="layerAssetsImport" @click-cancel="handleSearch"/>
    <r-e-table
        ref="tableRef"
        :dataRequest="getLockInstallList"
        :columns="tableColumn"
        :query="formSearch"
        :height="790"
    >
      <el-table-column slot="toolbar" label="操作" width="200">
        <template slot-scope="{ row }">
          <div class="table-tools">
            <span class="table-btn" @click="ckHandle(row)">修改</span>
            <span class="table-btn" @click="delFamily(row)">删除</span>
          </div>
        </template>
        <modifyPage ref="modifyPage" @handleSearch="handleSearch"/>
      </el-table-column>
    </r-e-table>
  </section>
</template>

<script>
import {tableColumn} from "./data";
import {
  delFamily,
  modifier,
  personList,
  getProceedsPayTotalApi,
} from "@/api/staff-management";
import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";

export default {
  name: "staff-management",
  data() {
    return {
      tableColumn,
      communityList: [],
      paymentChannels: [],
      formSearch: {
        type: "",
        company: "",
        keyWord: "",
      },
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      },
    };
  },
  components: {
    dialogSaveActivity: () => import("./components/dialog-save-activity.vue"),
    modifyPage: () => import("./components/modifyPage.vue"),
    layerAssetsImport: () => import("./components/layer-assets-import"),
  },
  methods: {
    // 导入
    importbtn() {
      this.$refs["layerAssetsImport"].openDialog();
    },
    handleSearch(isSearch = true) {
      // 搜索
      console.log(this.formSearch, 6789);
      // this.$refs.tableRef.pNumber = 1;
      this.pNumber = this.$refs.tableRef.pNumber
      console.log('this.pNumber',this.pNumber);
      this.$refs["tableRef"].getTableData(isSearch);
    },
    // 重置按钮
    handleReset() {
      this.formSearch = {};
      Promise.resolve(this.formSearch).then(() => this.handleSearch());
    },
    // 人员列表
    getLockInstallList(params) {
      // params.uuid = this.equipmentData.uuid;
      return personList(params);
    },
    async mounted() {
      this.getProceedsPayTotalApi();
      personList().then((res) => {
        this.paymentChannels = res.list;
      });
    },

    // 修改
    ckHandle(row) {
      this.$refs["modifyPage"].layerAddEditCommunityInit(row);
    },
    // 删除
    delFamily(params) {
      this.$confirm("此操作将删除该工作人员,是否继续?", "注意", {
        type: "warning",
      })
          .then(async () => {
            const loading = this.$loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            delFamily(params)
                .then((res) => {
                  MessageSuccess("删除工作人成功");
                  loading.close();
                  this.handleSearch(false)
                })
                .catch((err) => {
                  MessageError("删除工作人失败");
                  loading.close();
                });
          })
          .catch(() => {
          });
    },
    // 新增
    saveActivity() {
      this.$refs["dialogSaveActivity"].openDialog();
    },
    // 2024-4-17-新增点击空白关闭组件
    pickerclick(e){

      this.$refs['typrRef'].blur();
    },
  },
  mounted() {
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.repair-management {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    //justify-content: center;
  }

  /deep/ .r-e-table {
    .el-table .cell {
      //white-space: nowrap;
      text-align: center;
    }
  }
}
</style>